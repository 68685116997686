.Container {
    margin: 1.5rem 0;
    position: relative;
    display: flex;
    flex-direction: column;

    span {
        font-family: 'IBM Plex Mono', monospace;
    }
}

.TableWrapper {
    overflow: auto;
}

.ColumnHeader {
    display: flex;
    align-items: center;
    justify-content: center;

    button {
        margin-left: 0.5rem;
    }
}

.ConditionColumn {
    button {
        margin-right: 0.5rem;
    }
}

.Select {
    span {
        font-size: 0.7rem;
    }
}

.Match {
    > div > div {
        background-color: #46e1e0 !important;
    }
}

.Container {
  display: flex;
  flex-direction: column;

  label {
    font-size: 0.75rem;
    color: dimgray;
    margin-bottom: 0.25rem;
  }
}

.Dropdown {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 1rem;
  padding: 1rem;
}

.Color {
  width: 2rem;
  height: 2rem;
  border: 1px solid dimgray;
  border-radius: 5px;
  position: relative;

  &:hover {
    cursor: pointer;

    &:after {
      content: "☑️";
      display: flex;
      align-items: center;
      justify-content: center;
      width: 2rem;
      height: 2rem;
      position: absolute;
      font-size: 2rem;
      color: gray;
    }
  }
}

.Test {
  .ant-select-selection-item {
    background-color: red;
  }
}
.Container {
  display: flex;
  flex-direction: column;

  label {
    font-size: 0.75rem;
    color: dimgray;
    margin-bottom: 0.25rem;
  }
}

.SpinnerWrapper {
  width: 240px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 27px;
  padding-top: 5px;
}

.SelectWithButton {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

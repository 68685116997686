.Container {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
        height: 9.08rem;
        margin-right: 2rem;
    }
}

.Header {
    width: 100%;

    h1 {
        font-family: 'Amatic SC', cursive;
        font-size: 3rem;
        font-weight: bold;
        color: #36b0b0;
    }

    div {
        font-family: 'IBM Plex Mono', monospace;
    }
}

.Logout {
    position: absolute;
    color: #36b0b0;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.5rem;
    right: 2rem;
    top: 3.5rem;

    .LogoutImage {
        font-size: 2rem;
    }

    &:hover {
        color: #2d9393;
        cursor: pointer;
    }
}

.Container {
  position: fixed;
  right: 0;
  bottom: 100px;
  max-width: 3rem;

  background-color: #36b0b0;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border: 2px solid black;
  border-right: none;

  padding: 1rem 1rem 1rem 1.5rem;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  font-size: 0.9rem;
  font-weight: bold;
  text-align: center;

  z-index: 1000;

  &:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  }

  &:active {
    background-color: #2d9393;
  }

  div {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
}

.Blocked {
  display: none;
}
.Wrapper {
    border: 1px solid #36b0b0;
    border-radius: 15px;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    gap: 1rem;

    button {
        margin-top: 1rem;
    }
}

.Container {
  padding: 1rem;
}

.SpinnerWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 55px;
}

.Content {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  flex-wrap: wrap;
}

.Button {
  margin-top: 1.4rem;
}

.Error {
  color: red;
  margin-top: 1.2rem;
}
.Footer {
  width: 100%;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
  height: 30px;
}

.FooterContent {
  text-align: right;

  span {
    margin: 0 0.25rem;
  }

  a {
    font-weight: 500;
  }
}

.Version {
  min-width: 4rem;
}
.Container {
  margin: 1.5rem 0;
  position: relative;
  display: flex;
  flex-direction: column;

  span {
    font-family: 'IBM Plex Mono', monospace
  }
}

.TableWrapper {
  overflow: auto;
}

.IdColumn {
  min-width: 20rem;
}

.GroupColumn {
  min-width: 10rem;
}

.Select {
  min-width: 80px;
}

.Yes, .No {
  font-size: 1.75rem;
  cursor: pointer;
  box-shadow: rgba(0, 0, 0, 0.16) 0 1px 4px;
  border-radius: 50%;

  &:hover{
    box-shadow: rgba(0, 0, 0, 0.24) 0 3px 8px;
  }
}

.Yes {
  background-color: #46e1e0;
}

.No {
  color: gray;
}

.NameColumn {
  button {
    margin-right: 0.5rem;
  }
}